import React from 'react';
import Layout from '../components/layout';

export default function Nosotros() {
    return (
        <Layout>
            <h1>
                Nosotros
            </h1>
        </Layout>
    )
}
